import { autoinject } from "aurelia-framework";

@autoinject
export class FormattingService {
    formatDisplayText(wordToDisplay: string) {
        const words = wordToDisplay.split(" ");

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].toLowerCase();
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }

        return words.join(" ");
    }
}
