import { State } from "./state";

export const updateValues = (state: State, values: Array<any>) => {
	const newState = Object.assign({}, state);

	return newState;
}



