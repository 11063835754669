import { autoinject, Aurelia } from "aurelia-framework";
import { config } from "../config";
import * as moment from "moment";

@autoinject()
export class AuthenticationService {
    aurelia: Aurelia;
    session: { token: string; expiration: string };

    client: any;

    constructor(aurelia: Aurelia) {
        this.aurelia = aurelia;

        const sessionToken = localStorage[config.tokenName] || null;

        if (sessionToken != null) this.session = JSON.parse(sessionToken);
    }

    async loginAsync(email: string, password: string) {
        let fetchUrl = `/login`;

        const returnedData = await fetch(fetchUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        if (returnedData.status == 401) {
            return "Unknown User";
        } else if (returnedData.status == 403) {
            return "Inactive Subscription";
        }

        try {
            const session = await returnedData.json();

            localStorage[config.tokenName] = JSON.stringify(session);
            this.session = session;

            this.aurelia.setRoot("app");

            return "Login Successful";
        } catch (exception) {
            return this.session;
        }
    }

    logout() {
        // Clear from localStorage
        localStorage[config.tokenName] = null;

        // .. and from the session object
        this.session = null;

        // .. and set root to login.
        this.aurelia.setRoot("authentication-page");
    }

    isAuthenticated() {
        return this.session !== null;
    }

    static isLoggedIn(): boolean {
        let sessionToken = localStorage[config.tokenName];

        if (sessionToken == null || sessionToken.indexOf("token") == -1)
            return false;

        let tokenObject = JSON.parse(sessionToken);

        // console.log(tokenObject);

        return AuthenticationService.confirmOrInvalidate(tokenObject);
    }
    static confirmOrInvalidate(tokenObject: ITokenObject): boolean {
        if (tokenObject == null) return false;

        // console.log(moment(tokenObject.expiration).diff(moment()));

        if (moment(tokenObject.expiration).diff(moment()) < 0) {
            localStorage[config.tokenName] = null;
            return false;
        }

        return true;
    }
}

export interface ITokenObject {
    token: string;
    expiration: string;
}
